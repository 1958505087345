const data = () => [
  {
    name: 'Todo List (Vanilla JS)',
    description: 'This project is a list manager that will allow a user organize a list of their tasks. They can conveniently create, update and delete tasks. The application is tested using the Jest library.',
    imageURL: 'https://res.cloudinary.com/emmii/image/upload/v1668925300/my_portfolio/todo-list_zscvc5.png',
    languages: ['HTML', 'CSS', 'JavaScript'],
    hostedURL: 'https://emmiiorji.github.io/todo-list/',
    githubURL: 'https://github.com/emmiiorji/todo-list',
  },
  {
    name: 'Simple JavaScript Calculator',
    description: 'A simple JavaScript calculator capable of performing simple mathematical operations like addition, subtraction, division and multiplication',
    imageURL: 'https://user-images.githubusercontent.com/20554583/173040713-94b2bbf7-3b79-4243-8b53-ec7caf30e7e5.png',
    languages: ['HTML', 'CSS', 'JavaScript'],
    hostedURL: 'https://emmiiorji.github.io/Simple-Calculator_JS/',
    githubURL: 'https://github.com/emmiiorji/Simple-Calculator_JS',
  },
  {
    name: 'Todo List (React)',
    description: "A daily selection of privately personalized reads; no accounts or sign-ups required. has been the industry's standard",
    imageURL: 'https://user-images.githubusercontent.com/20554583/172984165-c99e401a-915c-4287-b5ae-d656f7a4688f.png',
    languages: ['React', 'HTML', 'CSS'],
    hostedURL: 'https://list-my-todos.onrender.com/',
    githubURL: 'https://github.com/emmiiorji/todo-list_react',
  },
  {
    name: 'PM Global Workshop',
    description: "This is a website that provides an overview of an upcoming Project Managers' global workshop. The website shows pertinent information about the workshop, including the program, speakers, sponsors, and contact information.",
    imageURL: 'https://res.cloudinary.com/emmii/image/upload/v1650824023/my_portfolio/pm-global-home_rns1kg.jpg',
    imgMobile: 'https://res.cloudinary.com/emmii/image/upload/v1665660297/my_portfolio/project-mgt-workshop_mobile_fo38n5.jpg',
    languages: ['HTML', 'CSS', 'Javascript'],
    hostedURL: 'https://emmiiorji.github.io/project-mgt-workshop/',
    githubURL: 'https://github.com/emmiiorji/project-mgt-workshop',
  },
  {
    name: 'Worldwide Holidays App',
    description: "This mobile web application developed with React/Redux lets you view global holidays from one point, reading data from the Calendarific's API. The countries' data is loaded from Open Disease.",
    imageURL: 'https://res.cloudinary.com/emmii/image/upload/v1669138470/my_portfolio/holidays-app_l9pi1u.png',
    languages: ['React', 'HTML', 'CSS'],
    hostedURL: 'https://holidays-worldwide.onrender.com',
    githubURL: 'https://github.com/emmiiorji/holidays-worldwide',
  },
  {
    name: 'Scriptmond Leaderboard',
    description: 'Displays scores by different players of a game. It also allows you to submit your score. All data is preserved thanks to an external Leaderboard API service.',
    imageURL: 'https://res.cloudinary.com/emmii/image/upload/v1669112978/my_portfolio/leaderboard_desktop_alordv.png',
    imgMobile: 'https://res.cloudinary.com/emmii/image/upload/v1669112978/my_portfolio/leaderboard_mobile_sfnkxr.png',
    languages: ['HTML', 'CSS', 'Javascript'],
    hostedURL: 'https://emmiiorji.github.io/leaderboard-list/',
    githubURL: 'https://github.com/emmiiorji/leaderboard-list',
  },
  {
    name: 'Math Magicians App',
    description: 'A Single Page App (SPA) developed with React that allows users to make simple calculations and read a random math-related quote.',
    imageURL: 'https://res.cloudinary.com/emmii/image/upload/v1666193436/my_portfolio/Math-Magician-Desktop_p0qbuw.jpg',
    imgMobile: 'https://res.cloudinary.com/emmii/image/upload/v1666193436/my_portfolio/Math-Magician-Mobile_lyfdhe.jpg',
    languages: ['HTML', 'CSS', 'React'],
    hostedURL: 'https://world-math-magicians.netlify.app/',
    githubURL: 'https://github.com/emmiiorji/math-magicians-app',
  },
  {
    name: 'Private Investigators',
    description: "Full-stack app designed to make the process of finding and booking an investigator as easy and seamless as possible. It's a perfect tool for anyone in need of investigative services.",
    imageURL: 'https://res.cloudinary.com/emmii/image/upload/v1691603101/my_portfolio/Screenshot_2023-08-09_184432_tecujf.png',
    // imgMobile: 'https://res.cloudinary.com/emmii/image/upload/v1666193436/my_portfolio/Math-Magician-Mobile_lyfdhe.jpg',
    languages: ['React', 'Ruby on Rails', 'PostgreSQL'],
    hostedURL: 'https://private-investigators.onrender.com/',
    githubURL: 'https://github.com/emmiiorji/private-investigators',
  },
  {
    name: 'Forex Academy',
    description: 'This is an online learning platform for users interested in learning FOREX trading, This website is built with comprehensive courses from the basics of forex market to advanced trading strategies.',
    imageURL: 'https://res.cloudinary.com/emmii/image/upload/v1686919370/my_portfolio/Screenshot_2023-06-16_133935_x679md.png',
    imgMobile: 'https://res.cloudinary.com/emmii/image/upload/v1686919503/my_portfolio/Screenshot_2023-06-16_134450_h518ei.png',
    languages: ['React', 'Node.js', 'Express', 'MySQL'],
    hostedURL: 'https://pipsgod.netlify.app/',
    githubURL: 'https://github.com/emmiiorji/pips-god-api',
  },
];

export default data();
